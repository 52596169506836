import { Injectable } from '@angular/core';
import { FingerprintjsProAngularService } from '@fingerprintjs/fingerprintjs-pro-angular';
import { CollectorApi } from 'src/app/shared/apis/collector.api';
import { LocalStorageService } from './localstorage.service';

@Injectable({
  providedIn: 'root',
})
export class CollectorService {
  constructor(
    private localStorageService: LocalStorageService,
    private fpService: FingerprintjsProAngularService,
    private collectorApi: CollectorApi
  ) {}

  /**
   * 自動傳送APP下載第三方推薦碼
   */
  async sendAppInviteCode() {
    const inviteCode = this.localStorageService.inviteCodeSignal() || '';
    const ip = this.localStorageService.clientIp || '';

    const extendedResult = await this.fpService.getVisitorData({ extendedResult: true }, true);
    const { device, os, osVersion } = extendedResult;

    window.alert(
      `前端已經按了, \n inviteCode: ${inviteCode}, \n ip: ${ip}, \n device: ${device}, \n os: ${os}, \n osVersion: ${osVersion}`
    );

    this.collectorApi.sendInviteCodeInfo({ inviteCode, ip, device, os, osVersion }).subscribe();
  }
}
